<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <!-- <b-col md="12">
                <ValidationProvider
                  name="Language"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Language" label-for="mc-language">
                    <select class="form-control" v-model="model.languageCode">
                      <option
                        v-for="(i, index) in languages"
                        :key="index"
                        :value="i.code"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->
              <b-col md="12">
                <app-collapse accordion :type="collapseType">
                  <app-collapse-item title="Header">
                    <b-row>
                      <b-col md="6">
                        <ValidationProvider
                          name="Pre Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Pre Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Pre Title"
                              v-model="model.header.preTitle"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="6">
                        <ValidationProvider
                          name="Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.header.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="6">
                        <ValidationProvider
                          name="Subtitle"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Subtitle"
                            label-for="mc-subtitle"
                          >
                            <b-form-input
                              id="mc-subtitle"
                              placeholder="Subtitle"
                              v-model="model.header.subtitle"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Image Header"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Image" label-for="mc-icon">
                            <b-form-file
                              v-model="model.header.image"
                              @change="storeHeaderImage"
                              placeholder="Choose a file..."
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Process">
                    <b-row>
                      <b-col md="12">
                        <ValidationProvider
                          name="Process Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Process Title"
                              v-model="model.process.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section Steps</h5>
                        <span v-for="(page, idx) in 3" :key="idx">
                          <b-row>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Title ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Title"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Title"
                                    v-model="model.process.steps[idx].title"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                              <ValidationProvider
                                :name="'Step Image ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group label="Image" label-for="mc-icon">
                                  <b-form-file
                                    v-model="model.process.steps[idx].logo"
                                    @change="storeImageSteps($event, idx)"
                                    placeholder="Choose a file..."
                                  />
                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="12">
                              <ValidationProvider
                                :name="'Description ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Description"
                                  label-for="mc-title"
                                >
                                  <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Description"
                                    rows="4"
                                    v-model="
                                      model.process.steps[idx].description
                                    "
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </span>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Sample Banner">
                    <b-row>
                      <b-col md="6">
                        <ValidationProvider
                          name="Sample Banner Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.downloadSampleBanner.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Image Sample Banner"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Image" label-for="mc-icon">
                            <b-form-file
                              v-model="model.downloadSampleBanner.image"
                              @change="storedownloadSampleBannerImage"
                              placeholder="Choose a file..."
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="12">
                        <ValidationProvider
                          name="Sample Banner Description"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Description"
                            label-for="mc-title"
                          >
                            <b-form-textarea
                              id="textarea-default"
                              placeholder="Textarea"
                              rows="4"
                              v-model="model.downloadSampleBanner.description"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Benefit">
                    <b-row>
                      <b-col md="12">
                        <ValidationProvider
                          name="Benefit Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.benefit.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Benefit Subtitle"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Subtitle"
                            label-for="mc-subtitle"
                          >
                            <b-form-input
                              id="mc-subtitle"
                              placeholder="Subtitle"
                              v-model="model.benefit.subtitle"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Features">
                    <b-row>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section Fatures's</h5>
                        <span v-for="(page, idx) in model.features" :key="idx">
                          <b-row>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Features Title ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Title"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Title"
                                    v-model="model.features[idx].title"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Features Subitle ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Subtitle"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Subtitle"
                                    v-model="model.features[idx].subtitle"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="12">
                              <ValidationProvider
                                :name="'Logo ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group label="Logo" label-for="mc-icon">
                                  <b-form-file
                                    v-model="model.features[idx].logo"
                                    @change="storeImageFeature($event, idx)"
                                    placeholder="Choose a file..."
                                  />
                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <button
                            type="button"
                            class="btn btn-danger mr-2"
                            @click="deleteFeatures(idx)"
                            v-if="model.features.length > 1"
                          >
                            <i class="fa fa-trash"></i> Delete Row Features</button
                          >&nbsp;
                          <div class="divider my-1">
                            <div class="divider-text">End of Row Features</div>
                          </div>
                        </span>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="addMoreFeatures"
                        >
                          Add More Features
                        </b-button>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="FAQ">
                    <b-row>
                      <b-col md="12">
                        <ValidationProvider
                          name="Faq Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Process Title"
                              v-model="model.faq.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section FAQ's</h5>
                        <span v-for="(page, idx) in model.faq.faqs" :key="idx">
                          <b-row>
                            <b-col md="12">
                              <ValidationProvider
                                :name="'Question ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Question"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Question"
                                    v-model="model.faq.faqs[idx].question"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="12">
                              <ValidationProvider
                                :name="'Answer ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Answer"
                                  label-for="mc-title"
                                >
                                  <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Answer"
                                    rows="4"
                                    v-model="model.faq.faqs[idx].answer"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <button
                            type="button"
                            class="btn btn-danger mr-2"
                            @click="deleteFaq(idx)"
                            v-if="model.faq.faqs.length > 1"
                          >
                            <i class="fa fa-trash"></i> Delete Content</button
                          >&nbsp;
                          <div class="divider my-1">
                            <div class="divider-text">
                              End of Row Question & Answer
                            </div>
                          </div>
                        </span>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="addMoreFaq"
                        >
                          Add More FAQ
                        </b-button>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Promo Banner">
                    <b-row>
                      <b-col md="6">
                        <ValidationProvider
                          name="Promo Banner Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.promoBanner.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Image Promo Banner"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Image" label-for="mc-icon">
                            <b-form-file
                              v-model="model.promoBanner.image"
                              @change="storepromoBannerImage"
                              placeholder="Choose a file..."
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="12">
                        <ValidationProvider
                          name="Promo Banner Description"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Description"
                            label-for="mc-title"
                          >
                            <b-form-textarea
                              id="textarea-default"
                              placeholder="Textarea"
                              rows="4"
                              v-model="model.promoBanner.description"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>

            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'how-we' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      collapseType: "margin",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "content/contribute",
        redirect: "content-contribute",
        language: "languages",
        upload: "misc/upload",
      },
      model: {
        header: {
          title: "",
          subtitle: "",
          preTitle: "",
          images: "",
        },
        benefit: {
          title: "",
          subtitle: "",
        },
        downloadSampleBanner: {
          title: "",
          description: "",
          image: "",
        },
        promoBanner: {
          title: "",
          description: "",
          image: "",
        },
        process: {
          title: "",
          steps: [
            {
              no: 1,
              title: "",
              description: "",
              image: "",
            },
            {
              no: 2,
              title: "",
              description: "",
              image: "",
            },
            {
              no: 3,
              title: "",
              description: "",
              image: "",
            },
            {
              no: 4,
              title: "",
              description: "",
              image: "",
            },
          ],
        },
        faq: {
          title: "",
          faqs: [
            {
              question: "",
              answer: "",
            },
          ],
        },
        features: [
          {
            logo: "",
            title: "",
            subtitle: "",
          },
        ],
      },
      messages: "",
      languages: [],
    };
  },
  mounted() {
    let _ = this;
    _.getLanguage();
    _.getData();
  },
  methods: {
    storepromoBannerImage(e) {
      let _ = this;
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.promoBanner.image = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    storedownloadSampleBannerImage(e) {
      let _ = this;
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.downloadSampleBanner.image = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    storeHeaderImage(e) {
      let _ = this;
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.header.image = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addMoreFaq() {
      let _ = this;
      _.model.faq.faqs.push({
        answer: null,
        question: null,
      });
    },

    deleteFaq(x) {
      let _ = this;
      _.model.faq.faqs.splice(x, 1);
      // _.selectedContents.splice(x, 1);
    },

    addMoreFeatures() {
      let _ = this;
      _.model.features.push({
        logo: null,
        title: null,
        subtitle: null,
      });
    },

    deleteFeatures(x) {
      let _ = this;
      _.model.features.splice(x, 1);
      // _.selectedContents.splice(x, 1);
    },

    storeImageSteps(e, idx) {
      let _ = this;
      console.log(e);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.process.steps[idx].image = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    storeImageFeature(e, idx) {
      let _ = this;
      console.log(e);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.features[idx].logo = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getLanguage() {
      let _ = this;
      axios.get(_.config.language).then((response) => {
        let _ = this;
        _.languages = response.data.rows;
      });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            _.model.title = _.model.header.title;
            _.model.subtitle = _.model.header.subtitle;
          });
      }
    },
    save() {
      let _ = this;

      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: "Data has been saved",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .patch(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Success",
                text: "Data has been Updated",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
  watch: {
    "model.code"(newValue) {
      this.model.code = newValue.toLowerCase();
    },
  },
};
</script>
